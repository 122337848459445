import { DeviceData } from '../../models';

export class SetApiUrl {
  static readonly type = '[App Setup] Set API Host';
  constructor(public url: string) {}
}

export class SetHardwareDetails {
  static readonly type = '[App Setup] Set Hardware Details';
}

//#region Device Registration

export class GetDeviceRegistrationCode {
  static readonly type = '[Device Registration] Get Registration Code';
}

export class CompleteDeviceRegistration {
  static readonly type = '[Device Registration] Complete Device Registration';
}

export class UpdatedDevice {
  static readonly type = '[Device Registration] Updated Device';
  constructor(public device: DeviceData) {}
}

export class RemovedDevice {
  static readonly type = '[Device Registration] Removed Device';
}

//#endregion
