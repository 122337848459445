import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PageConstant } from '../constants/page.constant';

import { AppState } from '../store/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class DeviceGuard implements CanActivate {
  constructor(private store: Store, private navController: NavController) {}

  canActivate(): Observable<boolean> | boolean {
    const isDeviceRegistered = this.store.selectSnapshot(AppState.isDeviceRegistered);
    if (!isDeviceRegistered) {
      this.navController.navigateRoot([PageConstant.DEVICE_REGISTRATION_PAGE]);
      return false;
    }
    return true;
  }
}
