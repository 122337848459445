import { AppState } from './app/app.state';

import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { ErrorState } from './error/error.state';

const STORE_STATES = [AppState, ErrorState];

@NgModule({
  imports: [
    NgxsModule.forRoot(STORE_STATES, {
      developmentMode: environment.DEV
    })
  ]
})
export class StoreModule {}
