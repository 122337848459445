import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { AppComponent } from './app.component';
import { StoreModule } from '../store/store.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxActioncableService } from '@csventures/ngx-actioncable';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { IonicStorageModule } from '@ionic/storage';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    StoreModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.DEV
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsResetPluginModule.forRoot()
  ],
  providers: [NgxActioncableService],
  bootstrap: [AppComponent]
})
export class AppModule {}
