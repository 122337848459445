import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeviceRegistrationPageError, ResetError } from './error.action';

export interface ErrorStateModel {
  errorMessage: string;
}

@State<ErrorStateModel>({
  name: 'error',
  defaults: {
    errorMessage: ''
  }
})
@Injectable({
  providedIn: 'root'
})
export class ErrorState {
  //#region Selectors

  @Selector()
  static getErrorMessage(state: ErrorStateModel) {
    return state.errorMessage;
  }

  @Action(DeviceRegistrationPageError)
  deviceRegistrationPageError({ patchState }: StateContext<ErrorStateModel>, { error }) {
    patchState({ errorMessage: error });
  }

  @Action(ResetError)
  resetError({ patchState }: StateContext<ErrorStateModel>) {
    patchState({
      errorMessage: null
    });
  }
}
