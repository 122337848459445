import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { applyPolyfills, defineCustomElements } from '../../../dist/libs/weekly-menu-component/loader/index.js';

import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

if (!environment.DEV) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

applyPolyfills().then(() => {
  defineCustomElements(window);
});
