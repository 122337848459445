import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { PageConstant } from '../constants/page.constant';
import { DeviceGuard } from '../guards/device.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: PageConstant.DEVICE_REGISTRATION_PAGE,
    pathMatch: 'full'
  },
  {
    path: PageConstant.DEVICE_REGISTRATION_PAGE,
    loadChildren: () =>
      import('./../pages/device-registration/device-registration.module').then((m) => m.DeviceRegistrationPageModule)
  },
  {
    path: PageConstant.MENU_PAGE,
    loadChildren: () => import('./../pages/menu/menu.module').then((m) => m.MenuPageModule),
    canActivate: [DeviceGuard]
  },
  {
    path: PageConstant.SUPPORT_PAGE,
    loadChildren: () => import('./../pages/support/support.module').then((m) => m.SupportPageModule),
    canActivate: [DeviceGuard]
  },

  { path: '**', redirectTo: PageConstant.DEVICE_REGISTRATION_PAGE }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
