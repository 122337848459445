import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';

import { DeviceState } from '../../models';
import { ConfigProvider } from '../../providers/config.provider';
import { DeviceService } from '../../services/device/device.service';
import {
  CompleteDeviceRegistration,
  GetDeviceRegistrationCode,
  RemovedDevice,
  SetApiUrl,
  SetHardwareDetails,
  UpdatedDevice
} from './app.action';
import { StateResetAll } from 'ngxs-reset-plugin';
import { NavController } from '@ionic/angular';
import { PageConstant } from '../../constants/page.constant';
import Bowser from 'bowser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Router } from '@angular/router';
import { CommonConstant } from '../../constants';
import { DeviceRegistrationPageError } from '../error/error.action';
import { of } from 'rxjs';
import { ErrorConstant } from '../../constants/error.constant';

export interface AppStateModel {
  apiBaseUrl: string;
  device: DeviceState;
}
@State<AppStateModel>({
  name: 'app',
  defaults: {
    apiBaseUrl: '',
    device: {
      id: undefined,
      status: undefined,
      name: '',
      facility_id: undefined,
      pos_location_id: undefined,
      operating_system: '',
      hardware_id: undefined,
      device_type: '',
      tenant: 'synergy',
      key: undefined,
      local_os: null,
      uuid: '',
      is_native: false,
      platform: undefined,
      token: undefined
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class AppState {
  constructor(
    private ngZone: NgZone,
    private store: Store,
    private deviceService: DeviceService,
    private configProvider: ConfigProvider,
    private navController: NavController,
    private router: Router
  ) {}

  @Selector()
  static isDeviceRegistered(state: AppStateModel) {
    return !!state.device.token;
  }

  //android, ios or web
  @Selector()
  static getPlatform(state: AppStateModel) {
    return state.device.platform;
  }

  @Selector()
  static getDeviceKey(state: AppStateModel) {
    return state.device.key;
  }

  @Selector()
  static getApiBase(state: AppStateModel) {
    return state.apiBaseUrl;
  }

  //Get Token
  @Selector()
  static getToken(state: AppStateModel) {
    return state.device.token;
  }

  @Selector()
  static getDevice(state: AppStateModel) {
    return state.device;
  }

  @Selector()
  static getFacilityId(state: AppStateModel) {
    return state.device?.facility_id;
  }

  //Sets host and domain data
  @Action(SetApiUrl)
  setApiHost({ patchState }: StateContext<AppStateModel>, { url }: SetApiUrl) {
    patchState({
      apiBaseUrl: url
    });
  }

  //Get code to register device
  @Action(GetDeviceRegistrationCode)
  getCode(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    return this.deviceService.getDevice(state.apiBaseUrl, state.device.uuid, state.device.local_os.name).pipe(
      catchError((err) => {
        ctx.dispatch(new DeviceRegistrationPageError(ErrorConstant.REGISTER_DEVICE_ERROR));
        return of(null);
      }),
      tap((result) => {
        const { status, device } = result;
        ctx.patchState({
          device: {
            ...state.device,
            ...device,
            status,
            token: device.key + ':' + state.device.uuid
          }
        });

        if (status === 'active') {
          //Update the provider host if the device is not a synergy device (synergy is set by default)
          if (device.tenant !== CommonConstant.TENANT_TYPE.SYNERGY) {
            this.configProvider.configHost(device.tenant);
          }

          // this.configProvider.configHost(device.tenant);
          ctx.dispatch(new CompleteDeviceRegistration());
        }
      })
    );
  }

  @Action(CompleteDeviceRegistration)
  onDeviceRegistrationComplete() {
    this.ngZone.run(() => this.navController.navigateRoot([PageConstant.MENU_PAGE]));
  }

  //Device was updated in Admin Site
  @Action(UpdatedDevice)
  async updatedDevice(ctx: StateContext<AppStateModel>, { device }: UpdatedDevice) {
    const state = ctx.getState();
    if (this.router.url.includes(PageConstant.DEVICE_REGISTRATION_PAGE)) {
      ctx.patchState({
        device: {
          ...state.device,
          ...device
        }
      });
      ctx.dispatch(new CompleteDeviceRegistration());
    } else {
      ctx.dispatch(new RemovedDevice());
    }
  }

  //Device was removed in the Admin Site
  @Action(RemovedDevice)
  async removedDevice() {
    this.store.dispatch(new StateResetAll());
    this.ngZone.run(() => this.navController.navigateRoot([PageConstant.DEVICE_REGISTRATION_PAGE]));
  }

  //#endregion

  @Action(SetHardwareDetails)
  async setHardwareDetails(ctx: StateContext<AppStateModel>) {
    const ua = window.navigator.userAgent;
    const { os } = Bowser.parse(ua);
    const is_native = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform();

    const domain = ctx.getState().apiBaseUrl;

    const device = is_native ? await Device.getId() : null;
    if (is_native) {
      // incase we have old device uuid we need to migrate it. Otherwise, let register a new device
      const oldUuid = await this.deviceService.getStorageUuid();
      const nativeUuid = device.uuid + CommonConstant.MENUBOARD_DEVICE_CODE;
      if (nativeUuid && oldUuid && oldUuid !== nativeUuid) {
        // only call migrate with the uuid is different.
        const result = await this.deviceService.migrateDeviceHardwareId(domain, oldUuid, nativeUuid).toPromise();
        if (result) {
          await this.deviceService.setStorageUuid(nativeUuid);
        }
      }
    }
    let { uuid } = is_native ? device : await this.deviceService.getUuid();
    if (is_native) {
      uuid += CommonConstant.MENUBOARD_DEVICE_CODE;
    }
    const state = ctx.getState();
    ctx.patchState({
      device: {
        ...state.device,
        local_os: os,
        uuid,
        is_native,
        platform
      }
    });

    ctx.dispatch(new GetDeviceRegistrationCode());
  }

  //Get support data
  @Selector()
  static getSupportData(state: AppStateModel) {
    return {
      os: state.device.local_os.name || '',
      uuid: state.device.uuid,
      facility_id: state.device.facility_id,
      tenant: state.device.tenant,
      device_name: state.device.name,
      device_key: state.device.key
    };
  }
}
