export class CommonConstant {
  // static readonly MENUBOARD_DEVICE_CODE = '-mb';
  static readonly MENUBOARD_DEVICE_CODE = '_mb';

  /** Key to store uuid under in storage */
  static readonly UUID_KEY = 'ms_device_uuid_key';

  static readonly TENANT_TYPE = {
    SYNERGY: 'synergy',
    MEALSUITE: 'mealsuite',
    REVERA: 'revera',
    MYUSUITE: 'myusuite'
  };

  static readonly ENVIRONMENT = {
    PRODUCTION: 'production',
    DEV: 'dev',
    STAGING: 'staging'
  };

  static readonly WEBSOCKET_CHANNELS = {
    DEVICE_CHANNEL: 'DeviceActivityChannel'
  };

  static readonly VERSION_FILE = {
    path: '../VERSION.txt'
  };
}
