import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInfo } from '@capacitor/device';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { CommonConstant } from '../../constants';
import { RegisterDeviceResponse } from '../../models';

const REGISTER_DEVICE_TOKEN = 'appconfigtool:3F.42N.iJVEy9A3bP';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  nativeDevice: DeviceInfo;
  private requestHeaders = {
    headers: new HttpHeaders({
      Authorization: REGISTER_DEVICE_TOKEN
    }).set('Accept', '*/*')
  };

  constructor(private http: HttpClient, private platform: Platform, private storage: Storage) {}

  get isDevice(): boolean {
    return this.isAndroidDevice || this.isIOS; //ON_DEVICE || this.electron.isElectronApp;
  }

  get isAndroidDevice() {
    return this.platform.is('android') && !this.platform.is('mobileweb');
  }

  get isIOS() {
    return this.platform.is('ios') && !this.platform.is('mobileweb');
  }

  get isELO(): boolean {
    return this.isAndroidDevice && this.nativeDevice?.manufacturer?.includes('Elo');
  }

  get isELOMobile(): boolean {
    return this.isELO && this.nativeDevice?.model === 'M50';
  }

  getDevice(domain: string, hardware_id: string, operating_system: string) {
    return this.http.post<RegisterDeviceResponse>(`${domain}/api/devices/v1/register_device`, null, {
      headers: this.requestHeaders.headers,
      params: {
        hardware_id,
        operating_system
      }
    });
  }

  async getStorageUuid() {
    return await this.storage.get(CommonConstant.UUID_KEY);
  }

  async setStorageUuid(uuid: string) {
    this.storage.set(CommonConstant.UUID_KEY, uuid);
  }

  async getUuid() {
    let uuid = await this.getStorageUuid();
    if (!uuid) {
      uuid = uuidv4();
      this.setStorageUuid(uuid);
    }
    return { uuid };
  }

  migrateDeviceHardwareId(domain: string, hardware_id: string, new_hardware_id: string) {
    return this.http
      .patch<{ success: boolean }>(
        `${domain}/api/devices/v1/register_device`,
        {
          hardware_id,
          new_hardware_id
        },
        this.requestHeaders
      )
      .pipe(map((response) => response.success));
  }

  getVersion = () =>
    this.http.get(CommonConstant.VERSION_FILE.path, {
      responseType: 'text'
    });
}
